<template>
	<div class="protect_box">
		<top-nav @back="back" backImage bgColor="#fff"><span class="color_black">保障计划详情</span></top-nav>
		<div class="content_box">
			<table class="table">
				<tr>
					<td colspan="2">{{ proName }}</td>
				</tr>
				<tr>
					<td>保障范围</td>
					<td>保险金额</td>
				</tr>
				<tr v-for="(item, index) in protectList" :key="index">
					<td>
						{{ item.contentName }}
					</td>
					<td width="50%;">{{ item.price }}</td>
				</tr>
				<tr>
					<td colspan="2">
						承保年龄<span v-if="age">({{ age }})</span>
					</td>
				</tr>
				<tr v-for="item in planList" :key="item.price_id">
					<td colspan="2">
						{{ item.view_time }}
					</td>
				</tr>
			</table>
		</div>
		<div class="content_box mt_20">
			<div class="title">投保须知</div>
			<div v-html="information.hint" class="text_content"></div>
		</div>
	</div>
</template>

<script>
import { productDetail, productDictInfo } from '@/request/api';

export default {
	data() {
		return {
			protectList: [],
			proName: '',
			age: '',
			planList: [],
			information: {},
		};
	},
	methods: {
		back() {
			this.$router.back();
		},
	},
	mounted() {
		const { productId, comboId } = this.$route.params;
		productDetail(productId).then(res => {
			const comboList = res.combos.filter(item => item.comboId == comboId)[0];
			this.protectList = comboList?.contents;
			this.proName = res.product.name + '-' + comboList?.comboName;
			this.age = res.product.insureAge;
			this.information = res.information;
		});
		// 获取产品计划
		productDictInfo(productId, comboId).then(res => {
			this.planList = res.insureTimeList;
		});
	},
};
</script>

<style lang="scss" scoped>
.protect_box {
	background-color: $b_border_color;
}
.content_box {
	padding: 0.2rem 0.4rem;
	word-break: break-all;
	font-size: 0.2rem;
	background-color: $background_color1;
}
.table {
	width: 100%;
	text-align: center;
}
tr {
	background-color: $b_border_color;
	td {
		padding: 0.1rem 0;
	}
}
.color_black {
	color: $b_font_color_info;
}
.mt_20 {
	margin-top: 0.2rem;
}

.title {
	border-bottom: solid 1px $b_border_color;
	padding-bottom: 0.2rem;
	font-size: 0.28rem;
}
.text_content {
	padding: 0.2rem 0;
	word-break: break-all;
}
</style>
